import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal'
import { BtnPrimary } from '../components/ItemButton';
import Loading from '../components/Loading';
import liff from '@line/liff';

export default function Login() {
  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)
  const [msgError, setMsgError] = useState('เกิดข้อผิดพลาด')
  const [modalError, setModalError] = useState(false)
  const [msgModalError, setMsgModalError] = useState('ไม่สามารถทำรายการได้')
  const [hasErrorType, setHasErrorType] = useState(0)

//   const [DataLogin, setDataLogin] = useState({})
  const [DataLoginStatus, setDataLoginStatus] = useState(0)
  const [DataLoginStatusMsg, setDataLoginStatusMsg] = useState('')

  const fetchLogin = async () => {
    let liffAccessToken: string | null = liff.getAccessToken();
    
    try {
    //   alert(liffAccessToken)

      const resLogin = await fetch(`${process.env.REACT_APP_API_URL}/liff/api/customerinfo`, {
        method: 'POST',
        body: JSON.stringify({
            "line_access_token": liffAccessToken
        }),
        headers: {
            "x-application-secret-key": 'b5e64ba6-1d27-4c40-bc65-2e686e394d62',
            "Content-Type": "application/json",
            "Accept": "application/json",
            // 'Authorization': 'Bearer ' + accesstokenValue
        }
        });
      
        if(!resLogin.ok) {
          return resLogin.text().then(text => { 
              if(resLogin.status === 401) {
                  if(text === 'Unauthorized wrong group') {
                      setHasError(false)
                      setMsgError(`เกิดข้อผิดพลาด`)
                      setMsgModalError(`กรุณาเข้าระบบ VIP เพื่อใช้งาน`)
                      setHasErrorType(1)
                  } else {
                      setHasError(true)
                      setMsgError(`คุณยังไม่มีสิทธิ์เข้าใช้งานระบบ กรุณาติดต่อเจ้าหน้าที่`)
                      setMsgModalError(`คุณยังไม่มีสิทธิ์เข้าใช้งานระบบ กรุณาติดต่อเจ้าหน้าที่`)
                      setHasErrorType(0)
                  }
                  
              } else {
                  setMsgModalError(`Error[${resLogin.status}] : ${text}`)
                  setHasErrorType(0)
              }
              setModalError(true)
          })
      } else {
          resLogin
          .json()
          .then(res => {
                console.log('resLogin:', res)
                console.log('resLoginStatus:', resLogin.status)

                if(!!res.isms_code || !!res.url || !!res.background ) {
                    navigate(`/home`, { state: { isms_code: res.isms_code, url_external: res.url, background_info: res.background, latk: liffAccessToken } })
                } else {
                    setModalError(true)
                    setMsgModalError(`ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูล [ isms_code:${res.isms_code}, url: ${res.url}, background: ${res.background}]`)
                }
         })
         .catch(err => {
            console.log('[Error]:',err);
            if(resLogin.status === 400 && err === 'Multiple users with ISMS code found') {
                setHasError(true)
                setDataLoginStatus(resLogin.status)
                // setDataLoginStatusMsg(resLogin.message)
                setDataLoginStatusMsg(err.toString())
            } else {
                setHasError(true)
                setMsgError(err.toString())
            }
         })
      }
    } catch (error) {
      setModalError(true)
    }
  }

  useEffect(() => {
    liff.init({
        liffId: process.env.REACT_APP_LIFF_ID as string, // Use own liffId
        withLoginOnExternalBrowser: true
    })
    .then(() => {
        if( !liff.isLoggedIn() ) {
            liff.login()
        } else {   
            fetchLogin()
        }
    })
    .catch((err) => {
        console.log(err.toString());
        setHasError(true)
        setMsgError(err.toString())
    });
  
    return () => {
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Modal.setAppElement('#root');
  
  return (
    <div className="login h-screen flex flex-col items-center bg-[linear-gradient(180deg,_#ffa000,_#e3821f)]">
      <Loading status="LiffisNotLoggedIn" color="text-secondary" />
      {
          hasError ? 
              <p className="mb-4 text-[12px] text-white font-promptregular">{ msgError }</p>
          : hasError && DataLoginStatus === 400 ?
            <p className="mb-4 text-[12px] text-white font-promptregular">{ DataLoginStatus } { DataLoginStatusMsg }</p>
          : null
      }

      {/* Modal Error */}
      <Modal
          isOpen={modalError}
          className="Modal"
          overlayClassName="Overlay"
      >
          <h1 className="mb-5 text-xl">{ msgModalError }</h1>
          {
              hasErrorType ? 
                  <BtnPrimary type="button" text="ตกลง" action={()=> { window.location.href = `https://liff.line.me/${process.env.REACT_APP_LIFF_ID}` }} />
              :
                  <BtnPrimary type="button" text="ตกลง" action={()=> { setModalError(false); }} />
          }
      </Modal>
    </div>
  )
}