import React from 'react'

const BtnPrimary = (props) => {
    const { type, text, addclass, action, icon } = props
    return (
        <button type={type} onClick={action} className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-center w-full bg-darkgray text-white ${addclass}`}>
            { icon ? <img src={icon} alt={'icon'} className="mr-2 xs:w-6 sm:w-5" /> : null }
            {text}
        </button>
    )
}

const BtnSecondary = (props) => {
    const { type, text, addclass, action, icon } = props
    return (
        <button type={type} onClick={action} className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-center w-full bg-white text-text-secondary border border-primary shadow-md ${addclass}`}>
            { icon ? <img src={icon} alt={'icon'} className="mr-2 xs:w-6 sm:w-5" /> : null }
            {text}
        </button>
    )
}


export {
    BtnPrimary,
    BtnSecondary
}