import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import liff from '@line/liff';
// import Bg from './../assets/img/bg-liff-info.jpeg'

export default function Login() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { isms_code, url_external, background_info, latk } = state || {}

  useEffect(() => {
    // Check state value
    if(!state || latk === localStorage.getItem('latk')) {
      navigate('/')
    } else {
      localStorage.setItem('latk', latk)
    }
    console.log('isms_code:',isms_code, ', url_external:',url_external, ', background_info:',background_info, ', latk:',latk)
  
    return () => {
      localStorage.clear()
    }
  }, [background_info, isms_code, latk, navigate, state, url_external])
  
  const openExternal = () => {
    // Check url include ? 
    let urlExternal: string = url_external.includes('?') ? url_external+`&openExternalBrowser=1` : url_external+`?openExternalBrowser=1`
    console.log('urlExternal:',urlExternal)
    
    const { userAgent } = navigator
    if(userAgent.includes('Line')) {
      liff.openWindow({
          url: urlExternal,
          external: true,
      });

    } else {
        window.parent.location = urlExternal
    }
  }

  return (
    <div className="w-full h-dvh flex justify-center items-center bg-contain" style={{'background': '#eeeeee url('+ background_info +') no-repeat', 'backgroundSize': '100% 100%'}}>
      {
        !!url_external ?
        <div onClick={()=> openExternal() }>
          <p className="text-5xl">{ isms_code }</p>
        </div>
        :
        <p className="text-5xl">{ isms_code }</p>
      }
    </div>
  )
}