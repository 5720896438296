import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import "./App.scss";
import Login from './pages/Login';
import Home from './pages/Home';

function App() {
  return (
      <div className="App container max-w-3xl mx-auto overflow-hidden">
            <BrowserRouter>
              <Routes>
                {/* {['/','home', 'prize', 'scanner', 'scanner-summary'].map((path,i) => <Route key={i} path={path} element={<Login />} />)} */}
                <Route path="/" element={<Login />} />



                <Route path="home" element={<Home />} />
              </Routes>
            </BrowserRouter>
      </div>
  );
}

export default App;
